<template>
    <VuePureLightbox class="lightbox">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <section class="signup has-text-centered">
                <div class="container">
                    <table class="reg-form has-text-centered">
                    <tr>
                        <td class="form-header">
                            <p class="org-description is-size-5">Create a new account:</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="form-content">
                            <table class="field-table email-table">
                                <tr>
                                    <td>
                                        <ValidationProvider rules="required|email|checkmail" name="Email" v-slot="{ errors, valid }">
                                            <b-field label="Email Address" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input type="email" maxlength="50" onkeyup='this.value = this.value.toLowerCase();' v-model="email">
                                                </b-input> 
                                            </b-field>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </table>
                            <table class="field-table">
                                <tr>
                                    <td>
                                        <form>
                                        <ValidationProvider :rules="{ regex: /(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/ }" vid="password" name="Password" v-slot="{ errors, valid }">
                                            <b-field label="Password" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input min-length="8" type="password" v-model="password" password-reveal >
                                                </b-input>
                                            </b-field>
                                        </ValidationProvider>
                                        </form>
                                    </td>
                                    <td>
                                        <form>
                                        <ValidationProvider rules="required|confirmed:password" vid="Confirmation" name="Password Confirmation" v-slot="{ errors, valid }">
                                            <b-field label="Confirm Password" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input type="password" v-model="confirmation" password-reveal >
                                                </b-input>
                                            </b-field>
                                        </ValidationProvider>
                                        </form>
                                    </td>
                                </tr>
                                
                            </table>
                            <table class="field-table">
                                <tr>
                                    <td>
                                        <ValidationProvider rules="required|min:2|max:20" name="First" v-slot="{ errors, valid }">
                                            <b-field min-length="2" max-length="20" label="First Name" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input v-model="first"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider rules="required|min:2|max:20" name="Last" v-slot="{ errors, valid }">
                                            <b-field min-length="2" max-length="20" label="Last Name" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input v-model="last"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                            </table>
                            <table class="field-table last-ft">
                                <tr>
                                    <td>
                                        <ValidationProvider rules="required" name="Invite" v-slot="{errors, valid}">
                                            <b-field label="Invite Code" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input minlength="4" maxlength="12" v-model="invite"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </td>
                                    <td class="plan">
                                        <ValidationProvider rules="required" name="Plan" v-slot="{ errors, valid }">
                                            <b-field class="plan-select" label="Select Plan" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-select v-model="plan">
                                                    <option class="option" value="0">Free</option>
                                                    <option class="option" value="1">Standard</option>
                                                    <option class="option" value="2">Pro</option>
                                                </b-select>
                                            </b-field>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                            </table>
                            <p class="disclaimer">By creating an account, you have read and agreed to our <a href="https://instacrypt.io/tos" target="_blank">Service Terms</a> and <a href="https://instacrypt.io/privacy" target="_blank">Privacy Policy</a>.</p>
                            <button class="button submit is-primary is-outlined" @click="handleSubmit(submit)">
                                <span>Submit</span>
                            </button>
                        </td>
                    </tr>
                    </table>
                </div>
            </section>
        </ValidationObserver>
    </VuePureLightbox>    
</template>

<script>
    import { ValidationObserver, ValidationProvider } from "vee-validate";

    export default {
        name: "signup",
        components: {
            ValidationObserver,
            ValidationProvider
        },
        props: {
            planid: {
                type: String,
                default: "1"
            },
            inv: {
                type: String,
                default: ""
            },
            mail: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                email: this.mail,
                password: "",
                confirmation: "",
                first: "",
                last: "",
                plan: this.planid,
                invite: this.inv,
                trans: "",
            }
        },
        computed: {
            loggedIn() {
                console.log("Login State: " + this.$store.state.auth.status.loggedIn);
                return this.$store.state.auth.status.loggedIn;
            },
        },
        created() {
            if (this.loggedIn) {
            console.log("redirect...");
            this.$router.replace('/dashboard/profile');
            }
        },
        methods: {
            load() {
                let loader = this.$loading.show({
                container: this.fullPage,
                onCancel: this.onCancel,
                active: this.isLoading
                });
                setTimeout(() => {
                loader.hide()
                },1000)
            },
            onCancel() {
                console.log('User cancelled the loader.')
            },
            submit() {
                requestAnimationFrame(() => {
                    this.load();
                    axios.post(
                                process.env.VUE_APP_API + "/v1/user/new", 
                                { email: this.email, password: this.password, first: this.first, last: this.last, plan: this.plan, invite: this.invite },
                                { useCredentials: true }
                            )
                            .then((res) => {
                                if (res.data.status == true) {
                                    console.log("Form submitted yay!");
                                    console.log(res.data);
                                    if (res.data.account.plan == "0") {
                                        this.$router.replace({ path: "/signupdone/1" });
                                    } else {
                                        this.$router.push({ path: "/payment/0/" + res.data.account.plan + "/" + res.data.account.email + "/" + res.data.account.trans})
                                    }
                                } else {
                                    console.log("Registration failed!");
                                    console.log(res.data)
                                    this.$router.replace({ path: "/signupdone/2" });
                                }
                            })
                            .catch((error) => {
                                console.log("Can't connect to server for registeration!");
                                console.log(error)
                                this.$router.replace({ path: "/signupdone/3" });
                            });
                });
            },
        }
    };
</script>

<style lang="scss">
    $main-color: #aac734;
    .lightbox {
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, .4);
    }
    .field-table {
        margin-bottom: 10px;
    }
    .field-table th,td {
        padding-left: 5%;
        padding-right: 5%;
    }
    .email-table {
        margin-left: 8px;
    }
    .plan {
        width: 35%;
        padding-right: 15px;
    }
    .plan-select {
        width: 100%;
        padding-right: 0px;
    }
    .last-ft {
        margin-bottom: 20px;
    }
    .signup {
        padding-top: 25px;
        padding-bottom: 100px;
        width: 100%;
    }
    .container {
        width: 100%;
        justify-content: center;
    }
    .disclaimer {
        font-size: 0.78rem;
        font-weight: 400;
    }
    .fa-check {
        color: $main-color;
    }
    .is-success {
        color: $main-color;
    }
    .select {
        color: $main-color;
    }
    .select.is-success select {
        color: $main-color;
        border-color: $main-color;
    }
    .select.is-success select:hover {
        border-color: $main-color;
    }
    .select.is-success:not(:hover)::after {
        border-color: $main-color;
    }
    .select:not(.is-multiple):not(.is-loading):hover::after {
        border-color: $main-color;
    }
    .select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
        box-shadow: rgba(170, 199, 52, 0.25);
    }
    .is-success.input {
        color: $main-color;
        border-color: $main-color;
    }
    .has-text-success {
        color: $main-color;
    }
    .submit {
        margin-top: 20px;
        text-align: center;
    }
    .reg-form {
        width: 100%;
        max-width: 600px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
        margin-left: auto;
        margin-right: auto;
        margin-top: 75px;
        padding-right: 10px;
    }
    .form-header {
        font-weight: bold;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
        background: $main-color;
    }
    .form-content {
        background: #F5F5F5;
        padding: 30px;
        padding-left: 15%;
        padding-right: 15%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .form-content .label {
        font-size: 0.78rem;
        text-align: left;
        padding-left: 5px;
        font-weight: 400;
        max-width: 600px;
    }
    .silent {
        visibility: hidden;
    }
</style>