var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VuePureLightbox',{staticClass:"lightbox"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('section',{staticClass:"signup has-text-centered"},[_c('div',{staticClass:"container"},[_c('table',{staticClass:"reg-form has-text-centered"},[_c('tr',[_c('td',{staticClass:"form-header"},[_c('p',{staticClass:"org-description is-size-5"},[_vm._v("Create a new account:")])])]),_c('tr',[_c('td',{staticClass:"form-content"},[_c('table',{staticClass:"field-table email-table"},[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|email|checkmail","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Email Address","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"email","maxlength":"50","onkeyup":"this.value = this.value.toLowerCase();"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)})],1),_c('td')])]),_c('table',{staticClass:"field-table"},[_c('tr',[_c('td',[_c('form',[_c('ValidationProvider',{attrs:{"rules":{ regex: /(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/ },"vid":"password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"min-length":"8","type":"password","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)})],1)]),_c('td',[_c('form',[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","vid":"Confirmation","name":"Password Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Confirm Password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})],1)]}}],null,true)})],1)])])]),_c('table',{staticClass:"field-table"},[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|min:2|max:20","name":"First"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"min-length":"2","max-length":"20","label":"First Name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{model:{value:(_vm.first),callback:function ($$v) {_vm.first=$$v},expression:"first"}})],1)]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|min:2|max:20","name":"Last"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"min-length":"2","max-length":"20","label":"Last Name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{model:{value:(_vm.last),callback:function ($$v) {_vm.last=$$v},expression:"last"}})],1)]}}],null,true)})],1)])]),_c('table',{staticClass:"field-table last-ft"},[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"rules":"required","name":"Invite"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Invite Code","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"minlength":"4","maxlength":"12"},model:{value:(_vm.invite),callback:function ($$v) {_vm.invite=$$v},expression:"invite"}})],1)]}}],null,true)})],1),_c('td',{staticClass:"plan"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Plan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"plan-select",attrs:{"label":"Select Plan","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-select',{model:{value:(_vm.plan),callback:function ($$v) {_vm.plan=$$v},expression:"plan"}},[_c('option',{staticClass:"option",attrs:{"value":"0"}},[_vm._v("Free")]),_c('option',{staticClass:"option",attrs:{"value":"1"}},[_vm._v("Standard")]),_c('option',{staticClass:"option",attrs:{"value":"2"}},[_vm._v("Pro")])])],1)]}}],null,true)})],1)])]),_c('p',{staticClass:"disclaimer"},[_vm._v("By creating an account, you have read and agreed to our "),_c('a',{attrs:{"href":"https://instacrypt.io/tos","target":"_blank"}},[_vm._v("Service Terms")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://instacrypt.io/privacy","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(".")]),_c('button',{staticClass:"button submit is-primary is-outlined",on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('span',[_vm._v("Submit")])])])])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }